import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import {
  Col,
  Container,
  Form,
  Row,
  Tab,
  Nav,
} from 'react-bootstrap';
import { useFormik } from 'formik';

import { useMutation, useQuery } from '@apollo/client';

import {
  ROUTES,
  REQUIRED_FIELD,
} from 'Constants';

import PageHeader from 'components/PageHeader/PageHeader';
import CustomSection from 'components/CustomSection/CustomSection';
import CustomInput from 'components/CustomInput/CustomInput';
import Larky from 'components/Larky';
import { useNotification } from 'components/Notification/Notification';

// Utils
import {
  formatNumber, getUserFeature,
} from 'Utils';

import Analytics from 'libs/Analytics';
import LocationService from 'services/LocationService';

// GraphQL
import {
  CREATE_LOCATION_MUTATION,
  UPDATE_LOCATION_MUTATION,
  BULK_UPLOAD_LOCATIONS_MUTATION,
} from 'graphql/mutations/locations';
import { GET_LOCATION } from 'graphql/queries/locations';

import styles from './CreateOrUpdateLocation.module.scss';
import {useInitialFocus} from "../../../../hooks/useInitialFocus";

const ANALYTICS_PAGE_CREATE = 'Create a Location';
const ANALYTICS_PAGE_UPDATE = 'Update a Location';
const FILE_NAME = 'locationform.jsx';
const FLOAT_ROUND_VALUE = 100000;

function convertToCSV(data) {
  const headers = Object.keys(data[0]);
  const rows = data.map(row => headers.map(header => row[header]).join(','));
  return [headers.join(','), ...rows].join('\n');
}

function handleAddressComponents(addressComponents, formValues) {
  const routeType = 'route';
  const stateType = 'administrative_area_level_1';
  const typeToFormField = {
    country: 'country',
    street_number: 'address',
    postal_town: 'city',
    locality: 'city',
    postal_code: 'zip',
  };
  Object.assign(typeToFormField, {
    [routeType]: 'address',
    [stateType]: 'state',
  });
  addressComponents.forEach((component) => {
    // GOOGLE'S EXAMPLE DOES types[0]!!!: https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
    const type = component.types[0];
    const formField = typeToFormField[type];
    if (typeof formField !== 'undefined') {
      switch (type) {
        case routeType:
          Object.assign(formValues, { [formField]: `${formValues[formField] === undefined ? '' : formValues[formField]} ${component.long_name}` });
          break;
        case stateType:
          Object.assign(formValues, { [formField]: component.short_name });
          break;
        default:
          Object.assign(formValues, { [formField]: component.long_name });
          break;
      }
    }
  });

  return formValues;
}

// const validationSchema = () => Yup.object({
//   autocomplete: Yup.string()
//     .when('isUpdate', (val, schema) => (val ? schema : schema.trim().required(REQUIRED_FIELD))),
//   name: Yup.string().trim().required(REQUIRED_FIELD),
//   description: Yup.string().trim(),
//   // latitude: Yup.number().required(REQUIRED_FIELD),
//   // longitude: Yup.number().required(REQUIRED_FIELD),
// });

const validationSchema = Yup.object({
  name: Yup.string().trim().required(REQUIRED_FIELD),
  description: Yup.string().trim(),
  latitude: Yup.number().when('$activeTab', {
    is: 'single',
    then: (schema) => schema.required('Latitude is required'),
    otherwise: (schema) => schema.nullable(),
  }),
  longitude: Yup.number().when('$activeTab', {
    is: 'single',
    then: (schema) => schema.required('Longitude is required'),
    otherwise: (schema) => schema.nullable(),
  }),
  file: Yup.mixed().when('$activeTab', {
    is: 'group',
    then: (schema) =>
      schema.required('A CSV file must be uploaded for location groups'),
    otherwise: (schema) => schema.nullable(),
  }),
  mappedColumns: Yup.object().when('$activeTab', {
    is: 'group',
    then: Yup.object().shape({
      locationName: Yup.string().required('Location name is required'),
      fullAddress: Yup.string().when(
        ['streetAddress', 'city', 'state', 'zip'],
        {
          is: (streetAddress, city, state, zip) =>
            !streetAddress || !city || !state || !zip, // Validate if these are not provided
          then: Yup.string().required(
            'Either full address or individual address components are required'
          ),
          otherwise: Yup.string().nullable(),
        }
      ),
      streetAddress: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable(),
      zip: Yup.string().nullable(),
    }),
  }),
});



const geoParams = [
  { key: 'address', title: 'Street Address:' },
  { key: 'city', title: 'City:' },
  { key: 'state', title: 'State:' },
  { key: 'zip', title: 'Zip:' },
  { key: 'country', title: 'Country:' },
  { key: 'latitude', title: 'Latitude:' },
  { key: 'longitude', title: 'Longitude:' },
];

function CreateOrUpdateLocation({ organizationId }) {
  const history = useHistory();
  const notification = useNotification();
  const locationService = new LocationService();
  useInitialFocus()

  const { id } = useParams();
  const locationId = id;

  const isUpdate = typeof locationId !== 'undefined';

  const [createLocationMutation] = useMutation(CREATE_LOCATION_MUTATION);
  const [updateLocationMutation] = useMutation(UPDATE_LOCATION_MUTATION);
  const [bulkUploadLocationsMutation] = useMutation(BULK_UPLOAD_LOCATIONS_MUTATION)

  const getLocationQuery = useQuery(GET_LOCATION, { variables: { id: locationId }, skip: true });

  const [isAutocompleteInit, setIsAutocompleteInit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLocationGroup, setIsLocationGroup] = useState(false);
  const [locationDetails, setLocationDetails] = useState({});
  const [isBulkUploadReady, setIsBulkUploadReady] = useState(false);
  const [categoryWarning, setCategoryWarning] = useState(false);
  const [dataMappingWarning, setDataMappingWarning] = useState(false);
  const [previousMappings, setPreviousMappings] = useState({});


  const [userHasLocationEditPermission, setUserHasLocationEditPermission] = useState(null);

  useEffect(async () => {
    const hasLocationEditPermission = await getUserFeature('geolocations', 2);
    setUserHasLocationEditPermission(hasLocationEditPermission);
  }, []);
  const [activeTab, setActiveTab] = useState('single');
  const [csvData, setCsvData] = useState([]);
  const [file, setFile] = useState(null);
  const [mappedColumns, setMappedColumns] = useState({
    'location name': '',
    'full address': '',
    'street address': '',
    'city': '',
    'state': '',
    'zip': '',
  });
  const [selectedColumns, setSelectedColumns] = useState({}); // Track selected columns

  const columnDescriptions = {
    'location name': 'Select the column containing the name of the individual location on each row',
    'full address': 'If one of your columns contains the entire address, select that here',
    'street address': 'If your file has separate columns for each part of the address, select the column for the street address here',
    'city': 'Select the column containing the city, if your file has separate columns for each address element',
    'state': 'Select the column containing the state',
    'zip': 'Select the column containing the Zip or postal code',
  };

  useEffect(() => {
    formik.setFieldValue('csvData', csvData || []);
    formik.setFieldValue('mappedColumns', mappedColumns || {});
  }, [csvData, mappedColumns]);

  useEffect(() => {
  const requiredFields = ['name', 'address', 'city', 'state', 'zip', 'country', 'latitude', 'longitude'];
  const isComplete = requiredFields.every((field) => mappedColumns[field] && mappedColumns[field].value);
  setIsBulkUploadReady(isComplete);
}, [mappedColumns]);

  const formik = useFormik({
      initialValues: {
      autocomplete: '',
      name: '',
      description: '',
      latitude: null,
      longitude: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      country: null,
      file: null,
      csvData: [], // Properly initialize
      mappedColumns: { locationName: '', address: '', city: '', state: '', zip: '', country: '', latitude: '', longitude: '' }, // Properly initialize
      isUpdate,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    context: { activeTab },
    onSubmit: async ({
      name,
      description,
      latitude,
      longitude,
      address,
      city,
      state,
      zip,
      country
    }) => {

       try {
        if (activeTab === 'group') {

          if (!csvData.length || !Object.keys(mappedColumns).length) {
            setCategoryWarning('Missing required CSV data or column mappings.');
            return;
          }


          // Handle bulk upload
          const csvPayload = createCsvPayload(csvData, mappedColumns);

          if (!csvPayload) {
            notification.alert('Please fix the CSV mappings before proceeding.', 'warning');
            setDataMappingWarning('Please map required fields: Either "Location Name" and "Full Address", or "Location Name", "Street Address", "City", "State", and "Zip".');
            return;
          }

          const csvString = convertToCSV(csvPayload); // Assuming convertToCSV() converts the payload to CSV format


          const locationPayload = {
            name,
            description,
            organizationId,
            fileContent: csvString
          };

          try {
            // Call the service
            const response = await LocationService.bulkUploadLocations(bulkUploadLocationsMutation,  locationPayload );

            if (response?.location) {
              notification.alert('Bulk upload successful!', 'success');
            } else {
              notification.alert('Bulk upload failed. Please try again.', 'danger');
            }
            history.push(`${ROUTES.LOCATIONS.MAIN}${isUpdate ? '' : '?autoupdate=true'}`);
          }
          catch (err) {
            setDataMappingWarning(err.message)
            notification.alert(err.message, 'danger');
            notification.alert('An error occurred during bulk upload. Please try again.', 'danger');
          }
        } else {
          const locationPayload = {
            name,
            description,
            latitude,
            longitude,
            address,
            city,
            state,
            zip,
            country,
          };


        let response;
        if (isUpdate) {
          response = await LocationService.update(
              updateLocationMutation,
              locationId,
              locationPayload,
          );
        } else {
          Object.assign(locationPayload, {organizationId});
          response = await LocationService.create(createLocationMutation, locationPayload);
        }

        const eventName = isUpdate ? Analytics.UPDATED_LOCATION : Analytics.CREATED_LOCATION;
        Analytics.track(eventName, Analytics.locationToAnalyticsProperties(
            locationPayload,
            response,
        ));

        history.push(`${ROUTES.LOCATIONS.MAIN}${isUpdate ? '' : '?autoupdate=true'}`);
      }
      } catch (err) {
        notification.alert(err.message, 'danger');
        Analytics.trackApplicationError(err.message, FILE_NAME, 'onSubmit');
      }
    },
  });

  const isDisabled = () => {
    if (!userHasLocationEditPermission) {
      return true
    }
    if (formik.isSubmitting) {
      return true;
    }
    return false;
  }

  const resetTabData = () => {
  // Reset tab-specific data
  setCsvData([]);
  setMappedColumns({
    'location name': '',
    'full address': '',
    'street address': '',
    'city': '',
    'state': '',
    'zip': '',
  });
  setSelectedColumns({});
  setFile(null);

  // Reset Formik fields
  formik.setFieldValue('csvData', []);
  formik.setFieldValue('mappedColumns', {
    locationName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    latitude: '',
    longitude: '',
  });
  formik.setFieldValue('file', null);
  formik.setFieldValue('autocomplete', ''); // Reset the autocomplete field
  formik.setFieldValue('address', null); // Reset address
  formik.setFieldValue('city', null); // Reset city
  formik.setFieldValue('state', null); // Reset state
  formik.setFieldValue('zip', null); // Reset zip
  formik.setFieldValue('country', null); // Reset country
  formik.setFieldValue('latitude', null); // Reset latitude
  formik.setFieldValue('longitude', null); // Reset longitude
   // Reset the sidebar
  setLocationDetails({}); // Clear sidebar details

  setCategoryWarning(false); // Clear any warning messages
  setDataMappingWarning(false); // Clear any warning messages
};

  const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    resetTabData(); // Clear previous state
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;

      // Use PapaParse to parse the CSV
      const Papa = require('papaparse');
      const parsed = Papa.parse(text, {
        skipEmptyLines: true, // Ignore empty lines
      });

      if (parsed.errors.length) {
        notification.alert('The file contains errors. Please fix them and try again.', 'danger');
        return;
      }

      let headers = parsed.meta.fields || [];
      const rows = parsed.data;

      // Intelligent header detection
      if (!headers.length) {
        // Generate generic headers if not present
        headers = rows[0].map((_, index) => `Column ${index + 1}`);
      } else {
        // Ensure the first row is included as data if headers are present
        headers = headers.map((header, index) => header || `Column ${index + 1}`);
      }

      // Build the CSV data with headers and rows
      const csvDataWithHeaders = [headers, ...rows];

      setCsvData(csvDataWithHeaders);
      setFile(file);
      notification.alert('File uploaded successfully. Please map the columns.', 'success');
    };
    reader.readAsText(file);
  }
};

  const createCsvPayload = (data, mappings) => {
  const headers = data[0];
  const rows = data.slice(1);

  // Validate mappings
  const hasFullAddress = mappings['location name'] && mappings['full address']?.value;
  const hasDetailedAddress =
    mappings['location name'] &&
    mappings['street address']?.value &&
    mappings['city']?.value &&
    mappings['state']?.value &&
    mappings['zip']?.value;

  if (!hasFullAddress && !hasDetailedAddress) {
    notification.alert(
      'Please map required fields: Either "Location Name" and "Full Address", or "Location Name", "Street Address", "City", "State", and "Zip".',
      'warning'
    );
    return null;
  }

  const getColumnIndex = (columnMapping) => {
    const columnLabel = columnMapping?.value;
    const columnIndex = columnLabel
      ? parseInt(columnLabel.replace('Column ', ''), 10) - 1
      : -1;
    return columnIndex >= 0 && columnIndex < headers.length ? columnIndex : -1;
  };

  // Map rows to payload
  const payload = rows.map((row) => {
    const location = {};

    // Map location name
    const nameIndex = getColumnIndex(mappings['location name']);
    location['location name'] = nameIndex !== -1 ? row[nameIndex]?.trim() || '' : '';

    if (hasFullAddress) {
      // Map full address
      const fullAddressIndex = getColumnIndex(mappings['full address']);
      location['fullAddress'] = fullAddressIndex !== -1 ? row[fullAddressIndex]?.trim() || '' : '';
    } else if (hasDetailedAddress) {
      // Map detailed address components
      ['street address', 'city', 'state', 'zip'].forEach((key) => {
        const columnIndex = getColumnIndex(mappings[key]);
        location[key.replace(' ', '')] = columnIndex !== -1 ? row[columnIndex]?.trim() || '' : '';
      });
    }

    return location;
  });

  return payload;
};


  const locationButtons = () => {
    if (isLocationGroup) {
      return (
        <Larky.Button
          className="mr-2"
          outlined
          size="xl"
          onClick={() => history.goBack()}
        >
          Close
        </Larky.Button>
      );
    }
    return (
      <>
        <Larky.Button
          className="mr-2"
          outlined
          size="xl"
          onClick={() => history.goBack()}
        >
          Cancel
        </Larky.Button>

        <Larky.Button
          size="xl"
          disabled={isDisabled()}
          isLoading={formik.isSubmitting}
          onClick={() => {
            // formik.submitForm();
            formik.handleSubmit();
          }}
          className={formik.isSubmitting || (isDisabled()) ? styles['disable-type-button'] : ''}
        >
          {`${isUpdate ? 'Update' : 'Add'} Location`}
        </Larky.Button>
      </>
    );
  };

  const getFilteredOptions = (key) => {
  // Get the currently selected values, excluding the current key's value if already selected
  const selectedValues = Object.values(selectedColumns).map(col => col.value);
  return csvData[0] // headers
    .map((col, index) => ({ label: col, value: `Column ${index + 1}` })) // Create options based on generic headers
    .filter((option) => !selectedValues.includes(option.value) || option.value === mappedColumns[key]);
};



  const handleColumnMappingChange = (key, value) => {
  setMappedColumns((prev) => {
    const updatedColumns = { ...prev, [key]: value };

    if (key === 'full address' && value) {
      // If "Full Address" is selected, clear and disable other fields
      updatedColumns['street address'] = '';
      updatedColumns.city = '';
      updatedColumns.state = '';
      updatedColumns.zip = '';
    } else if (
      ['street address', 'city', 'state', 'zip'].includes(key) &&
      value
    ) {
      // If any of "Street Address", "City", "State", or "Zip" is selected, clear and disable "Full Address"
      updatedColumns['full address'] = '';
    }

    return updatedColumns;
  });

  // Update selectedColumns for the dropdown state
  setSelectedColumns((prev) => ({
    ...prev,
    [key]: value,
  }));
};


  const capitalizeWords = (str) => {
  return str
    .split(' ') // Split the string into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words back into a string
};

const clearColumnMapping = () => {
  setMappedColumns({
    'location name': '',
    'full address': '',
    'street address': '',
    'city': '',
    'state': '',
    'zip': '',
  });
  setPreviousMappings({});
  setSelectedColumns({});
  setIsBulkUploadReady(false); // Ensure the bulk upload readiness is reset
  notification.alert('All mappings have been cleared.', 'success');
};

  const sidebarBox = () => {
    if (isLocationGroup) {
      const locationCount = (locationDetails.address && !Number.isNaN(locationDetails.address)) ? formatNumber(locationDetails.address) : '';
      return (
        <div>
          <h6>{'Location Count: '}</h6>
          <span>{`${locationCount}`}</span>
        </div>
      );
    }
    return (
      geoParams.map(({ key, title }) => (
        <div key={key}>
          <h6>{title}</h6>
          <span>{formik.values[key]}</span>
        </div>
      ))
    );
  };

  useEffect(() => {
    function initializeGoogleAutocomplete() {
      if (window.google && !isAutocompleteInit) {
        const UNITED_STATES_BOUNDS = new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(31.321967, -119.824831),
          new window.google.maps.LatLng(65.351378, -25.647274),
        );
        const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
          bounds: UNITED_STATES_BOUNDS,
        });
        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const gLocation = autocomplete.getPlace();
          if (gLocation.geometry) {
            const newFormValues = handleAddressComponents(
              gLocation.address_components,
              {
                autocomplete: gLocation.formatted_address,
                latitude: Math.round(gLocation.geometry.location.lat() * FLOAT_ROUND_VALUE)
                  / FLOAT_ROUND_VALUE,
                longitude: Math.round(gLocation.geometry.location.lng() * FLOAT_ROUND_VALUE)
                  / FLOAT_ROUND_VALUE,
              },
            );
            Object.keys(newFormValues)
              .forEach((key) => formik.setFieldValue(key, newFormValues[key]));
          }
        });
        setIsAutocompleteInit(true);
        Analytics.trackPageView(isUpdate ? ANALYTICS_PAGE_UPDATE : ANALYTICS_PAGE_CREATE);
      }
    }
    async function fetch() {
      if (isUpdate && formik.values.latitude === null && formik.values.longitude === null) {
        const data = await LocationService.index(getLocationQuery, locationId);
        setIsLocationGroup(data.isLocationGroup);
        setLocationDetails(data);
        formik.setValues({ ...formik.values, ...data });
      }
      setIsLoading(false);
    }
    fetch();
    initializeGoogleAutocomplete();
  }, [formik, isAutocompleteInit, locationId, locationService, isUpdate, getLocationQuery]);


  // console.log('isLocationgroup:  '+isLocationGroup + 'isUpdate:' + isUpdate)
  return (
    <Container className="p-sm-5 p-3" fluid>
      <PageHeader
        title={`${isUpdate ? 'Update' : 'Add'} a Location`}
        subtitle="Create and reuse locations to be used by your nudges and campaigns"
      />

      <Row>
        <Col className="col-12">
          <Form onSubmit={formik.handleSubmit}>
            <Container fluid>
              <Row>
                <Col md={12}>

                  <CustomSection >
                    <CustomInput
                        type="text"
                        name="name"
                        title="Name your location"
                        placeholder="Enter a name..."
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.errors.name}
                    />

                    <CustomInput
                        type="textarea"
                        name="description"
                        title="Give your location a description"
                        placeholder="Enter a description..."
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={formik.errors.description}
                    />

                    </CustomSection>

                </Col>

                <Col md={12}>
                  <br></br>
                  {/*<Nav variant="tabs" activeKey={activeTab} onSelect={setActiveTab} className="navbar-links">*/}
                  <Nav
                      variant="tabs"
                      activeKey={activeTab}
                      onSelect={(tab) => {
                        setActiveTab(tab);
                        resetTabData();
                        if (tab === 'single') {
                          setIsAutocompleteInit(false); // Reset to reinitialize
                          formik.setFieldValue('file', null); // Clear the file field
                        }
                      }}
                      className="navbar-links"
                    >

                    {isLocationGroup && isUpdate ? (
                    <Nav.Item style={{fontSize:'16pt',fontWeight:600}}>
                    <Nav.Link eventKey="group" className={'active-link'}>
                      Add location group
                    </Nav.Link>
                  </Nav.Item>):<Nav.Item style={{fontSize:'16pt', fontWeight:600}}>
                    <Nav.Link eventKey="single" className={activeTab === 'single' ? 'active-link' : ''}>
                      Add single location
                    </Nav.Link>
                  </Nav.Item >}
                    {!isLocationGroup && !isUpdate ? (
                    <Nav.Item style={{fontSize:'16pt',fontWeight:600}}>
                    <Nav.Link eventKey="group" className={'active-link'}>
                      Add location group
                    </Nav.Link>
                  </Nav.Item> ):null}

                </Nav>

                  {/* Custom Section for Single Location */}
                  {activeTab === 'single' ? (
                    <CustomSection>
                      {/* Single Location Inputs */}
                      {!isLocationGroup && (
                        <CustomInput
                          id="autocomplete"
                          type="text"
                          name="autocomplete"
                          title="Type your location"
                          placeholder="Enter a location..."
                          value={formik.values.autocomplete}
                          onChange={formik.handleChange}
                          error={formik.errors.autocomplete}
                          disabled={isLocationGroup}
                        />
                      )}

                    </CustomSection>
                  ) :  ((!isLocationGroup && !isUpdate) ? (
                    // Custom Section for Location Group
                    <CustomSection className={styles['full-width-section']}>
                      {/* Location Group Inputs */}

                      <h5>Upload list of locations</h5>
                      <p>Specify the file to import and confirm details</p>
                      {/*<input type="file" title="Upload a CSV file" accept=".csv" onChange={handleFileUpload}  />*/}
                      <input
                      type="file"
                      title="Upload a CSV file"
                      accept=".csv"
                      onChange={(event) => {
                        formik.setFieldValue('file', event.target.files[0]); // Set the file in Formik
                        handleFileUpload(event); // Existing upload logic
                      }}
                      className={formik.errors.file && formik.touched.file ? 'error-input' : ''}
                    />
                    {categoryWarning ? (<p className={styles.alert}>{categoryWarning}</p>) : (null)}

                      {csvData.length > 0 && (
                        <div className={styles.csvContainer}>

                          {/* Scrollable CSV Table */}
                          <h5>File Preview</h5>
                          <div className={styles.scrollableTableContainer}>
                            <table className={styles.csvTable}>
                              <thead>
                                <tr>
                                  {csvData[0].map((col, index) => (
                                    <th key={index}>{col}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {csvData.slice(1, 6).map((row, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                      <td key={cellIndex}>{cell}</td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>

                          <br></br>
                          <h5>Select column mapping</h5>
                          <p>Indicate which columns contain the details needed to create locations</p>
                          {dataMappingWarning ? (<p className={styles.alert}>{dataMappingWarning}</p>) : null}
                          <div className={styles.dropdownContainer}>
                            <div className={styles.undoButton}>
                            {/*<Larky.Button*/}
                            {/*  outlined*/}
                            {/*  size="md"*/}
                            {/*  onClick={clearColumnMapping}*/}
                            {/*  disabled={Object.keys(mappedColumns).every((key) => !mappedColumns[key])} // Disable if no mappings exist*/}
                            {/*>*/}
                            {/*  Reset Selection*/}
                            {/*</Larky.Button>*/}
                              <Larky.Button
                              size="md"
                              onClick={clearColumnMapping}
                              className={formik.isSubmitting || (isDisabled()) ? styles['disable-type-button'] : ''}
                            >
                              Reset Selection
                            </Larky.Button>
                          </div>

                          {Object.keys(mappedColumns).map((key) => {
                          const isFullAddressSelected = mappedColumns['full address'];
                          const isDetailedAddressSelected =
                            mappedColumns['street address'] ||
                            mappedColumns.city ||
                            mappedColumns.state ||
                            mappedColumns.zip;

                          const isDisabled =
                            (isFullAddressSelected && key !== 'full address' && key !== 'location name') || // Disable others if Full Address is selected
                            (isDetailedAddressSelected && key === 'full address'); // Disable Full Address if any detailed address field is selected

                          return (
                            <div key={key} className={styles.dropdownWrapper}>
                              <CustomInput
                                type="select"
                                name={key}
                                description={columnDescriptions[key]}
                                title={capitalizeWords(key)}
                                placeholder="Select..."
                                onChange={(value) => handleColumnMappingChange(key, value)}
                                value={mappedColumns[key]}
                                error={formik.errors[key]}
                                options={getFilteredOptions(key)}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                                disabled={isDisabled} // Dynamically disable dropdowns
                              />
                              {formik.errors[key] && <div className={styles.error}>{formik.errors[key]}</div>}
                            </div>
                          );
                        })}
                        </div>
                        </div>
                      )}
                    </CustomSection>
                  ):null)}
                </Col>

                {/* Sidebar for Single Location */}

                 {(activeTab === 'single' || (isLocationGroup && isUpdate)) && (
                  <Col md={7}>
                    <CustomSection className={styles['custom-card']}>
                      {!isLoading && sidebarBox()}
                    </CustomSection>
                  </Col>
                )}
              </Row>

              <Row className="justify-content-center py-5">
                {!isLoading && locationButtons()}
              </Row>
            </Container>


          </Form>
        </Col>
      </Row>
    </Container>
  );
}

CreateOrUpdateLocation.propTypes = {
  organizationId: PropTypes.number,
};

CreateOrUpdateLocation.defaultProps = {
  organizationId: null,
};

export default CreateOrUpdateLocation;
