import moment from 'moment';

export const SERVICE_URLS = {
    development: {
        PROXY: 'http://localhost:5001',
    },
    production: {
        PROXY: '/api',
    },
};

export const OUTPUT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const INPUT_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';
export const INPUT_DATE_FORMAT = 'MM/DD/YYYY';
export const INPUT_TIME_FORMAT = 'hh:mm A';
export const INPUT_TIME_FORMAT_ALT = 'h:mm A';
export const OUTPUT_TIME_FORMAT = 'HH:mm:ss';
export const PRODUCTION = 'production';
export const DEVELOPMENT = 'development';

export const ROLES = [
    {key: 'portal_user', color: 'green', value: 'User'},
    {key: 'portal_admin_user', color: 'purple', value: 'Admin'},
    {key: 'nudge_admin', color: 'blue', value: 'Nudge Admin'},
];

export const GEOLOCATION = 'Geolocation';
export const TIME_BASED = 'Time-based';

export const ARCHIVED = 'Archived';
export const DRAFT = 'Draft';
export const SCHEDULED = 'Scheduled';
export const INPROGRESS = 'In Progress';
export const SENT = 'Sent';
export const ACTIVE = 'Active';
export const COMPLETED = 'Completed';

export const NUDGE_STATUSES = {
    ARCHIVED: {color: 'purple-2', value: ARCHIVED},
    DRAFT: {color: 'orange', value: DRAFT},
    SCHEDULED: {color: 'green-2', value: SCHEDULED},
    INPROGRESS: {color: 'yellow', value: INPROGRESS},
    SENT: {color: 'blue-2', value: SENT},
    ACTIVE: {color: 'green-2', value: ACTIVE},
    COMPLETED: {color: 'blue-2', value: COMPLETED},
};

export const PAGINATION = {
    ITEMS_PER_PAGE: 25,
};

export const CUSTOM_TABLE = {
    FILTER_SELECT: [
        {title: 'Select all', value: 'all'},
        {title: 'Select none', value: 'none'},
        {title: 'Select all active', value: 'active'},
        {title: 'Select all completed', value: 'completed'},
        {title: 'Select all sent', value: 'sent'},
    ],
    FILTER_SORT: [
        {field: 'suggested', order: 'suggested', title: 'Suggested'},
        {field: 'created', order: 'desc', title: 'Date Created-Descending'},
        {field: 'created', order: 'asc', title: 'Date Created-Ascending'},
        {field: 'name', order: 'asc', title: 'Name-Ascending'},
        {field: 'name', order: 'desc', title: 'Name-Descending'},
        {field: 'title', order: 'asc', title: 'Title-Ascending'},
        {field: 'title', order: 'desc', title: 'Title-Descending'},
        {field: 'status', order: 'asc', title: 'Scheduled-Ascending'},
        {field: 'status', order: 'desc', title: 'Scheduled-Descending'}
    ],
    FILTER_TIME: [
        {title: 'All times', value: ''},
        {
            title: 'Last 24 hours',
            value: moment.utc().subtract(24, 'hours').format('YYYY-MM-DDTHH:mm'),
        },
        {
            title: 'Last 7 days',
            value: moment.utc().subtract(7, 'days').format('YYYY-MM-DDTHH:mm'),
        },
        {
            title: 'Last 30 days',
            value: moment.utc().subtract(30, 'days').format('YYYY-MM-DDTHH:mm'),
        },
        {
            title: 'Last 365 days',
            value: moment.utc().subtract(365, 'days').format('YYYY-MM-DDTHH:mm'),
        },
    ],
    FILTER_TYPE: [
        {title: 'All', value: ''},
        {title: GEOLOCATION, value: GEOLOCATION},
        {title: TIME_BASED, value: TIME_BASED},
    ],
    // FILTER_TYPE: [
    //     {title: 'Draft', value: 'Draft'},
    //     {title: 'Scheduled', value: 'Scheduled'},
    //     {title: 'Sent', value: 'Sent'},
    //     {title: 'All', value: ''},
    // ],
    FILTER_SEGMENTS_STATUS: [
        {title: 'All', value: 'all'},
        {title: 'Active', value: 'active'},
        {title: 'Unused', value: 'unused'},
    ],
    FILTER_CAMPAIGN_STATUS: [
        {title: 'All', value: ''},
        {title: 'Pending', value: 'Pending'},
        {title: 'Scheduled', value: 'Scheduled'},
        {title: 'Active', value: 'Active'},
        {title: 'Paused', value: 'Paused'},
        {title: 'Completed', value: 'Completed'},
    ],
    FILTER_NUDGE_STATUS: [
        {title: 'All', value: ''},
        {title: 'Draft', value: 'Draft'},
        {title: 'Scheduled', value: 'Scheduled'},
        {title: 'Sent', value: 'Sent'},
        {title: 'Archived', value: 'Archived'},
    ],

    FILTER_LOCATIONNUDGE_STATUS: [
        {title: 'All', value: ''},
        {title: 'Draft', value: 'Draft'},
        {title: 'Scheduled', value: 'Scheduled'},
        {title: 'Active', value: 'Active'},
        {title: 'Completed', value: 'Completed'},
        {title: 'Archived', value: 'Archived'},
    ],

    FILTER_CUSTOM_TYPE: [
        {title: 'All', value: 'all'},
        {title: 'Static', value: 'static'},
        {title: 'Dynamic', value: 'dynamic'},
        {title: 'Trellance', value: 'trellance'},
        {title: 'Hubspot', value: 'hubspot'},
        {title: 'DeepTarget', value: 'deeptarget'}
    ],
    FILTER_CREATED_AFTER: [
        {title: 'All', value: ''},
        {
            title: 'Last 7 days',
            value: moment.utc().subtract(7, 'days').format('YYYY-MM-DDTHH:mm'),
        },
        {
            title: 'Last 30 days',
            value: moment.utc().subtract(30, 'days').format('YYYY-MM-DDTHH:mm'),
        },
        {
            title: 'Last 90 days',
            value: moment.utc().subtract(90, 'days').format('YYYY-MM-DDTHH:mm'),
        },
        {
            title: 'Last 365 days',
            value: moment.utc().subtract(365, 'days').format('YYYY-MM-DDTHH:mm'),
        },
    ],
};

export const SEARCH_INDEX_NAMES = {
    NUDGE: 'nudge',
    LOCATION: 'location',
};

export const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
};

export const HEADERS = {
    APPLICATION_JSON: 'application/json;',
    UTF: 'charset=utf-8;',
};

export const CONDITION_TYPES = {
    ORGANIZATION: {
        ID: 1,
    },
    SEGMENT: {
        ID: 2,
    },
    SPEED: {
        ID: 3,
    },
    GEOFENCE: {
        ID: 4,
    },
    DELAY: {
        ID: 5,
    },
    ACTIVE_WINDOW: {
        ID: 6,
    },
    DATE_TIME_RANGE: {
        ID: 7,
    },
    SCHEDULED_ACTION: {
        ID: 8,
    },
};

export const NUDGE_TYPES = {
    GEOFENCE: {
        ID: 1,
    },
    SCHEDULED_ACTION: {
        ID: 2,
    },
};

export const ACTION_TYPE_SEND_NOTIFICATION = 1;
export const SCHEDULE_NOW_DELAY = 60;

export const ROUTES = {
    LOGIN: {
        BASE: '',
    },
    DASHBOARD: {
        BASE: '',
    },
    LOCATIONNUDGES: {
        BASE: 'locationnudges',
        CREATE: '/locationnudges/create',
        EDIT: '/locationnudges/edit',
        MAIN : '/locationnudges/main',
        ARCHIVED: '/locationnudges/main?filterStatus=5'
    },
    LOCATIONS: {
        BASE: 'locations',
        CREATE: '/locations/create',
        EDIT: '/locations/edit',
        MAIN: '/locations/main',
        ARCHIVED: '/locations/archived',
    },
    NUDGES: {
        BASE: 'nudges',
        CREATE: '/nudges/create',
        EDIT: '/nudges/edit',
        MAIN: '/nudges/main',
        DRAFTS: '/nudges/drafts',
        ARCHIVED: '/nudges/main?filterStatus=4'
    },
    USERS: {
        BASE: 'users',
        MAIN: '/users/main',
        INVITE: '/users/invite',
        ORGANIZATION: '/message-capping',
        PARTNER_MANAGEMENT: '/partner-management/main',
        MANAGE_ORGANIZATION: '/partner-management/edit',
        CREATE_ORGANIZATION: '/partner-management/create',
    },
    SEGMENTS: {
        BASE: 'segments',
        CREATE: '/segments/create',
        MAIN: '/segments/main',
        EDIT: '/segments/edit',
    },
    CAMPAIGNS: {
        BASE: 'campaigns',
        CREATE: '/campaigns/create',
        MAIN: '/campaigns/main',
        EDIT: '/campaigns/edit',
        ARCHIVED: '/campaigns/archived',
        GALLERY: '/campaigns/gallery',
    },
};

export const limitList = [
    {value: null, label: 'No Limit'},
    {value: 0.01, label: '1%'},
    {value: 0.03, label: '3%'},
    {value: 0.05, label: '5%'},
    {value: 0.10, label: '10%'},
    {value: 0.15, label: '15%'},
    {value: 0.20, label: '20%'},
    {value: 0.25, label: '25%'},
    {value: 0.50, label: '50%'},
    {value: 250, label: '250 Members'},
    {value: 500, label: '500 Members'},
    {value: 1000, label: '1,000 Members'},
];

export const PAGE_HEADER_BUTTONS = {
    LOGIN: {
        MAIN: {
            text: 'LOGIN',
            path: ROUTES.LOGIN,
        }
    },
    LOCATIONS: {
        CREATE: {
            text: 'ADD A LOCATION',
            path: ROUTES.LOCATIONS.CREATE,
        },
        MAIN: {
            text: 'VIEW MY LOCATIONS',
            path: ROUTES.LOCATIONS.MAIN,
        },
        ARCHIVED: {
            text: 'VIEW ARCHIVED',
            path: ROUTES.LOCATIONS.ARCHIVED,
        },
    },
    LOCATIONNUDGES: {
        CREATE: {
            text: 'CREATE A LOCATION NUDGE',
            path: ROUTES.LOCATIONNUDGES.CREATE,
        },
    },
    NUDGES: {
        CREATE: {
            text: 'CREATE AN ALERT NUDGE',
            path: ROUTES.NUDGES.CREATE,
        },
        // LOCATIONCREATE : {
        //     text: 'CREATE A LOCATION NUDGE',
        //     path: ROUTES.NUDGES.CREATE,
        // },
        // MAIN: {
        //     text: 'VIEW MY NUDGES',
        //     path: ROUTES.NUDGES.MAIN,
        // },
        // DRAFTS: {
        //     text: 'VIEW DRAFTS',
        //     path: ROUTES.NUDGES.DRAFTS,
        // },
        // ARCHIVED: {
        //     text: 'VIEW ARCHIVED',
        //     path: ROUTES.NUDGES.ARCHIVED,
        // },
    },
    USERS: {
        CREATE: {
            text: 'INVITE A USER',
            path: ROUTES.USERS.INVITE,
        },
    },
    SEGMENTS: {
        CREATE: {
            text: 'CREATE A SEGMENT',
            path: ROUTES.SEGMENTS.CREATE,
        },
        MAIN: {
            text: 'VIEW MY SEGMENTS',
            path: ROUTES.SEGMENTS.MAIN,
        },
    },
    CAMPAIGNS: {
        CREATE: {
            text: 'CREATE A CAMPAIGN',
            path: ROUTES.CAMPAIGNS.CREATE,
        },
        MAIN: {
            text: 'VIEW MY CAMPAIGNS',
            path: ROUTES.CAMPAIGNS.MAIN,
        },
        ARCHIVED: {
            text: 'VIEW ARCHIVED',
            path: ROUTES.CAMPAIGNS.ARCHIVED,
        },
    },
};

export const VALIDATION = {
    LOCATION: {
        NAME_LENGTH: 128,
        COUNTRY_LENGTH: 128,
        CITY_LENGTH: 128,
        ZIP_LENGTH: 5,
        MAX_LATITUDE: 90,
        MIN_LATITUDE: -90,
        MAX_LONGITUDE: 180,
        MIN_LONGITUDE: -180,
    },
    ANALYTICS: {
        CREATED_LOCATION: 'User Created Location',
        UPDATED_LOCATION: 'User Updated Location',
        DELETED_LOCATION: 'User Deleted Location',
        VIEWED_PAGE: 'User Viewed Page',
        CREATED_NUDGE: 'User Created Nudge',
        APPLICATION_ERROR: 'Application Error',
        SIGNED_UP: 'User Signed Up',
        SIGNED_IN: 'User Signed In',
        LOGGED_OUT: 'User Logged Out',
    },
};

export const GEOFENCE_PRESETS = [
    {label: 'About a half block radius', value: 60},
    {label: 'About 1 block radius', value: 125},
    {label: 'About 3 blocks radius', value: 375},
    {label: 'About 5 blocks radius', value: 625},
    {label: 'About 1 mile radius', value: 1609},
    {label: 'About 5 miles radius', value: 8047},
];

export const WEEKDAY_OPTIONS = [
    {value: 1, label: 'Monday'},
    {value: 2, label: 'Tuesday'},
    {value: 3, label: 'Wednesday'},
    {value: 4, label: 'Thursday'},
    {value: 5, label: 'Friday'},
    {value: 6, label: 'Saturday'},
    {value: 7, label: 'Sunday'},
];

export const ERRORS = {
    BACKEND: 'There was an error creating your nudge',
    EMAIL_INVITE_USER_EXISTS: 'A user with that email already exists',
    EMAIL_INVITE_GENERIC: 'There was an error sending the email invite',
    INVALID_EMAIL: 'You must enter a valid email',
};

export const MESSAGES = {
    INVITE_SENT: 'Invite Sent!',
    SENDING: 'Sending...',
};

export const BEGINNING_OF_DAY_TIME = moment()
    .set('seconds', 0)
    .set('minutes', 0)
    .set('hours', 0);
export const END_OF_DAY_TIME = moment()
    .set('seconds', 59)
    .set('minutes', 59)
    .set('hours', 23);
export const KEEN_ANALYSIS = {
    PROJECT_ID: '5c42271ac9e77c0001cf1846',
    READ_KEY:
        '8F57108D3A07354EB75834854D24F74F99BD0D4300ABBF5DD599F24BF549DFD901550397E7EE69E5804DA913D4A91A2E991E61612341394DE738863CA2B15444D9099987CE441283BDBE452DF8E58B35AA0740734E6E2DF180B8591A27B2DD9A',
};

export const rfc1738UrlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

export const phoneNumberRegex = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;

export const INPUT_TIME_FORMATS = [INPUT_TIME_FORMAT, INPUT_TIME_FORMAT_ALT];

export const REQUIRED_FIELD = 'This field is required';
export const DATE_VALIDATION = 'Date cannot be in the past';
export const EMAIL_VALIDATION = 'Invalid email';
export const PASSWORD_VALIDATION = 'Passwords must match';

/**
 * @type {{ [rule: string]: RegEx }}
 */
export const PASSWORD_RULES_REGEXS = {
    SPECIAL_CHARS: /[\W|_]{1,}/,
    LOWER_CASE: /[a-z]{1,}/,
    UPPER_CASE: /[A-Z]{1,}/,
    NUMBERS: /[0-9]{1,}/,
    LENGTH: /^.{8,25}$/,
};

/**
 * @type {{ [rule: string]: string }}
 */
export const PASSWORD_RULES_MESSAGES = {
    SPECIAL_CHARS: 'Have at least one special character (!@#$%#&*)',
    LOWER_CASE: 'Have at least one lower case character',
    UPPER_CASE: 'Have at least one capital letter',
    NUMBERS: 'Have at least one number',
    LENGTH: 'Have between 8 and 25 characters',
    NAME_LASTNAME_EMAIL:
        'Your password must not contain your name, lastname or your email',
};

export const UPDATE_PASSWORD_LOGIN_DISPATCHER = 'update-password';
export const TOKEN_EXPIRED_LOGIN_DISPATCHER = 'token-expired';
export const SESSION_EXPIRED_LOGIN_DISPATCHER = 'session-expired';

export const ENVIRONMENTS = [
    {label: 'Development (sandbox)', value: 'development'},
    {label: 'Production', value: 'production'},
];

export const TIME_HOUR = [
    {value: '01', label: '1'},
    {value: '02', label: '2'},
    {value: '03', label: '3'},
    {value: '04', label: '4'},
    {value: '05', label: '5'},
    {value: '06', label: '6'},
    {value: '07', label: '7'},
    {value: '08', label: '8'},
    {value: '09', label: '9'},
    {value: '10', label: '10'},
    {value: '11', label: '11'},
    {value: '00', label: '12'},
];

export const TIME_MINUTE = [
    {value: '00', label: '00'},
    {value: '05', label: '05'},
    {value: '10', label: '10'},
    {value: '15', label: '15'},
    {value: '20', label: '20'},
    {value: '25', label: '25'},
    {value: '30', label: '30'},
    {value: '35', label: '35'},
    {value: '40', label: '40'},
    {value: '45', label: '45'},
    {value: '50', label: '50'},
    {value: '55', label: '55'},
];

export const TIME_TYPE = [
    {value: 'A', label: 'AM'},
    {value: 'P', label: 'PM'},
];

export const NUMBER_OF_DAYS_TO_QUERY = 30;

export const NUMBER_OF_DAYS_TO_SHOW_ON_GRAPH = 14;

export const NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD = 90;

export const USER_RECEIVED_NOTIFICATION_PROPERTY_NAMES = {
    property_names: ['keen.timestamp', 'message_name', 'message_description', 'message_title', 'message_body'],
};

export const USER_TAPPED_NOTIFICATION_PROPERTY_NAMES = {
    property_names: ['keen.timestamp', 'message_name', 'message_description', 'message_title', 'message_body'],
};

export const USER_RECEIVED_NOTIFICATION_FILE_HEADERS = [
    'date', 'time', 'message_name', 'message_description', 'message_title', 'message_body',
];

export const USER_TAPPED_NOTIFICATION_FILE_HEADERS = [
    'date', 'time', 'message_name', 'message_description', 'message_title', 'message_body',
];

export const USER_RECEIVED_TAPPED_NOTIFICATION_FILE_HEADERS_CAMELCASE = [
    'date', 'time', 'message_name', 'message_description', 'message_title', 'message_body',
];

export const DWELL_PRESETS = [
    {value: 0, label: 'Deliver immediately', isTest: false},
    {value: 2, label: '2 minutes', isTest: false},
    {value: 5, label: '5 minutes', isTest: false},
    {value: 10, label: '10 minutes', isTest: false},
    {value: 15, label: '15 minutes', isTest: false},
    {value: 20, label: '20 minutes', isTest: false},
    {value: 30, label: '30 minutes', isTest: false},
    {value: 45, label: '45 minutes', isTest: false},
    {value: 60, label: '60 minutes', isTest: false},
    {value: 0.5, label: '30 seconds (used for testing only)', isTest: true},
];

export const BYPASS_LIMIT_OPTIONS = [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'},

];

export const MAX_FREQ_OPTIONS = [
    {value: 1440, label: 'Once a day', test: false},
    {value: 10080, label: 'Once a week', test: false},
    {value: 20160, label: 'Once every 2 weeks', test: false},
    {value: 43800, label: 'Once a month', test: false},
    {value: 131400, label: 'Once every 3 months', test: false},
    {value: 259200, label: 'Once every 6 months', test: false},
    {value: 518400, label: 'Once a year', test: false},
    {value: 60, label: 'Once an hour (used for testing only)', test: true},
];


export const MESSAGE_LINK_OPTIONS = [
    {value: 'Weblink', label: 'Web Link'},
    {value: 'Deeplink', label: 'Deep Link'},
];

export const NON_PRODUCTION_CADENCE_OPTIONS = [
    // value is in minutes
    {value: 5, label: '5 Minutes'},
    {value: 10, label: '10 Minutes'},
    {value: 15, label: '15 Minutes'},
    {value: 60, label: '1 Hour'},
];

export const CADENCE_OPTIONS = [
    // value is in minutes
    {value: 0, label: 'Immediately'},
    {value: 1440, label: '1 Day'},
    {value: 2880, label: '2 Days'},
    {value: 4320, label: '3 Days'},
    {value: 5760, label: '4 Days'},
    {value: 7200, label: '5 Days'},
    {value: 10080, label: '1 Week'},
    {value: 20160, label: '2 Weeks'},
    {value: 30240, label: '3 Weeks'},
    {value: 43800, label: '1 Month'},
    {value: 60480, label: '6 Weeks'},
    {value: 87600, label: '2 Months'},
    {value: 131400, label: '3 Months'},
    {value: 175200, label: '4 Months'},
    {value: 262800, label: '6 Months'},
    {value: 394200, label: '9 Months'},
    {value: 525600, label: '12 Months'},
];

export const TAP_BEHAVIOR = [
    {value: 0, label: 'Automatically remove them from any further messages', selected: true},
    {value: 1, label: 'Keep sending this message', selected: false},
];


export const CAMPAIGN_TYPE = [
    {value: 'Standard campaign', label: 'Let the Larky Nudge platform control delivery of your messages', selected: true},
    {value: 'API-triggered campaign', label: 'Orchestrate nudge delivery from another system connected to the Nudge API', selected: false},
];

export const NUDGE_TAP_BEHAVIOR = [
    {
        value: 0,
        label: 'Don\'t resend this message in the future for repeat visits of the selected location(s)',
        selected: true
    },
    {value: 1, label: 'Keep sending this message for repeat visits in the future', selected: false},
];

export const VIEW_ONLY_OR_EDITOR_OPTIONS = [
    {value: 1, label: 'View-Only', selected: true},
    {value: 2, label: 'Editor', selected: false},
];

export const ACTIVE_USER_OPTIONS = [
    {value: 1, label: 'Enabled - can login to the portal', selected: true},
    {value: 0, label: 'Disabled cannot login to the portal', selected: false},
];

// eslint-disable-next-line consistent-return
export const isNumber = (value) => {
    if (typeof value === 'string') {
        return !Number.isNaN(value);
    }
};
