/**
 * CampaignMessage.jsx
 *
 * Component that contains a table that displays Campaign's Nudges(messages)
 *
 */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { Button, Row } from 'react-bootstrap';

// Services
import CampaignService from 'services/CampaignService';

// Components
import CustomTable from 'components/CustomTable/CustomTable';
import { useNotification } from 'components/Notification/Notification';

// GraphQL
import { GET_CAMPAIGN_MESSAGES } from 'graphql/queries/campaigns';
import {
  REORDER_CAMPAIGN_NUDGES_MUTATION,
} from 'graphql/mutations/campaigns';

import { deleteCampaignRow } from 'Utils';

// Context
import PropTypes from 'prop-types';
import CampaignMessagesRow from '../CampaignMessagesRow/CampaignMessageRow';
import styles from '../CreateOrUpdateCampaign/CreateOrUpdateCampaign.module.scss';
import CampaignMessageModal from '../CampaignMessageModal/CampaignMessageModal';
import CampaignMessageReorderModal
  from '../CampaignMessageReorderModal/CampaignMessageReorderModal';

/*
* @param {Object} boolean, will disable campaign message actions like: 'Delete' or 'Edit' buttons
* */
function CampaignMessages({
  organizationId,
  campaignId,
  campaignType,
  status,
  nudgeAdditionDisabled,
  nudgeReorderingDisabled,
  setContainsNudges,
  showAnalytics,
  readOnly,
  handleShowCampaignNudgeAnalytics,
  campaignAnalyticsInDropDown,
  readOnlyUser,
  isArchived
}) {
  const notification = useNotification();
  const [campaignNudges, setCampaignNudges] = useState({});
  const getCampaignMessagesQuery = useQuery(GET_CAMPAIGN_MESSAGES, { skip: true });
  const [sequenceNumMax, setSequenceNumMax] = useState(0);
  const [fetchCampaignMessages, setFetchCampaignMessages] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showReorder, setShowReorder] = useState(false);
  const [deleteCampaignNudgeMutation] = useMutation(REORDER_CAMPAIGN_NUDGES_MUTATION);
  const [selectedNudge, setSelectedNudge] = useState(null);

  function calculateSequenceNumMax() {
    /** This is used to get the last sequenceNum attribute from the existing Campaign Nudges */
    let seqNumMax = sequenceNumMax;
    if (campaignNudges.totalResults && campaignNudges.totalResults > 0) {
      seqNumMax = Math.max.apply(Math, campaignNudges.hits.map((o) => o.sequenceNum));
    } else {
      seqNumMax = 0;
    }
    setSequenceNumMax(seqNumMax);
  }

  function updateContainsNudges() {
    // containsNudges is used for validation purposes in CreateOrUpdateCampaign
    // so me make sure that it stays valid whenever we make changes (Add/Delete) to campaign nudges
    if (campaignNudges.hits) {
      const contains = !!campaignNudges.hits.length;
      setContainsNudges(contains);
    }
  }
  useEffect(() => {
    updateContainsNudges();
  }, [campaignNudges]);

  useEffect(() => {
    calculateSequenceNumMax();
  }, [sequenceNumMax, campaignNudges, fetchCampaignMessages]);

  const getData = async (orderBy, sortOrder, start, params) => {
    const data = await CampaignService.getCampaignNudges(
      getCampaignMessagesQuery,
      organizationId,
      campaignId,
    );
    setCampaignNudges(data);

    return data;
  };
  const handleModalFormSubmission = (fetchMessages) => {
    if (fetchMessages) {
      setFetchCampaignMessages(true);
    }
  };
  const handleShowAdd = () => setShowAdd(true);
  const handleShowReorder = () => setShowReorder(true);

  const columns = campaignType === 'Standard campaign'
    ? ['Name', 'Title', 'Message', 'Delivery']
    : ['Name', 'Title', 'Message'];

  const editSelectedNudge = (nudge) => {
    setSelectedNudge(nudge);
    handleShowAdd();
  };
  return (
    <>
      <CustomTable
        getData={getData}
        fetchDataObj={{
          fetchMessages: fetchCampaignMessages,
          setFetchMessages: setFetchCampaignMessages,
        }}
        columns={columns}
        customRow={(data, handleSelect, updateCallback) => (
          <CampaignMessagesRow
            key={data.messageId}
            data={data}
            handleSelect={handleSelect}
            handleEditRow={() => { editSelectedNudge(data); }}
            status={status}
            readOnly={readOnly}
            campaignType={campaignType}
            campaignMessageDeleteDisabled={readOnly}
            handleDeleteRow={() => deleteCampaignRow(
              CampaignService,
              notification,
              deleteCampaignNudgeMutation,
              data.actionId,
              campaignNudges,
              campaignId,
              setCampaignNudges,
              setFetchCampaignMessages,
              data.name,
            )}
            handleShowCampaignNudgeAnalytics={() => {handleShowCampaignNudgeAnalytics(data)}}
            campaignAnalyticsInDropDown={campaignAnalyticsInDropDown}
          />
        )}
      />
      <Row
        className={styles.horizontal}
        style={{ justifyContent: 'center', paddingTop: '1.5em' }}
      >
        <CampaignMessageModal
          organizationId={organizationId}
          campaignId={campaignId}
          campaignType={campaignType}
          sequenceNumMax={sequenceNumMax}
          onSuccessfulSubmit={handleModalFormSubmission}
          fetchMessages={setFetchCampaignMessages}
          callback={setContainsNudges}
          show={showAdd}
          setShow={setShowAdd}
          selectedNudge={selectedNudge}
          campaignNudges={campaignNudges}
          setSelectedNudge={setSelectedNudge}
          showAnalytics={showAnalytics}
          readOnly={readOnly}
          readOnlyUser={readOnlyUser}
          isArchived={isArchived}
        />
        {nudgeAdditionDisabled ? null
          : (
            <Button variant="primary" onClick={handleShowAdd} style={{ color: 'white' }}>
              Add a nudge
            </Button>
          )}
        <CampaignMessageReorderModal
          campaignId={campaignId}
          show={showReorder}
          setShow={setShowReorder}
          data={campaignNudges.hits || []}
          onSuccessCallback={setFetchCampaignMessages}
        />
        {nudgeReorderingDisabled ? null
          : (
            <Button variant="primary" onClick={handleShowReorder} style={{ color: 'white', marginLeft: '1em' }}>
              Reorder nudges
            </Button>
          )}
      </Row>
    </>
  );
}

CampaignMessages.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  setContainsNudges: PropTypes.func.isRequired,
  nudgeAdditionDisabled: PropTypes.bool.isRequired,
  nudgeReorderingDisabled: PropTypes.bool.isRequired,
  status: PropTypes.number,
  showAnalytics: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  handleShowCampaignNudgeAnalytics: PropTypes.func,
  campaignAnalyticsInDropDown: PropTypes.bool,
  readOnlyUser: PropTypes.bool
};
CampaignMessages.defaultProps = {
  readOnly: false,
  status: null,
  handleShowCampaignNudgeAnalytics: () => null,
  campaignAnalyticsInDropDown: false,
};

export default CampaignMessages;
