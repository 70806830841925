import { gql } from '@apollo/client';

export const GET_CAMPAIGNS = gql`
    query GetCampaignsQuery(
      $organizationId: Int!
      $orderBy: String!
      $sortOrder: String!
      $rows: Int!
      $start: Int!
      $searchParam: CampaignParamInput!    
    ) {
        getCampaigns(
          organizationId: $organizationId
          orderBy: $orderBy
          sortOrder: $sortOrder
          rows: $rows
          start: $start
          searchParam: $searchParam
        ) {
          hits {
            archived
            id
            name
            description
            organizationId
            inclSegmentId
            created
            updated
            updatedBy
            status
            active
            startDate
            endDate
            tapBehavior
            memberCounts
            totalMessages
            activeRecipients
            statsNumerator30days
            statsDenominator30days
            statsPercent30days
            status
            statusDescription
            campaignCategoryId
            campaignCategoryValue
          }
          totalResults
        }
    }    
`;

export const GET_CAMPAIGN = gql`
    query GetCampaignsQuery(
      $organizationId: Int!
      $campaignId: Int!
    ) {
        getCampaign(organizationId: $organizationId, campaignId: $campaignId) {
          id
          name
          description
          campaignType
          organizationId
          inclSegmentId
          created
          updated
          updatedBy
          status
          active
          archived
          startDate
          endDate
          tapBehavior
          memberCounts
          totalMessages
          activeRecipients
          statsNumerator30days
          statsDenominator30days
          statsPercent30days
          status
          statusDescription
          templateId
          temporaryFlag
          isDraft
          archived
          campaignCategoryId
        }
    }    
`;

export const GET_CAMPAIGN_MESSAGES = gql`
    query GetCampaignMessagesQuery(
      $organizationId: Int!
      $campaignId: Int!
    ) {
        getCampaignNudges(organizationId: $organizationId, campaignId: $campaignId) {
          hits {
            actionId
            messageId
            name
            title
            message
            daysDelay
            sequenceNum        
            description
            startDeliveryTime   
            url
            guid
            urlLinkType
            onMon
            onTue
            onWed
            onThu
            onFri
            onSat
            onSun
          }
        totalResults
      }
    }    
`;

export const GET_CAMPAIGN_ANALYTICS = gql`
    query GetCampaignAnalyticsQuery(
      $campaignId: Int
    ) {
      getCampaignAnalytics(campaignId: $campaignId) {
      campaignNudgeAnalytics {
            actionId
            campaignId
            engagements
            engagementsRate
            organizationId
            title
            totalImpressions    
            uniqueRecipients    
          }
        overallEngagementRate
        overallTotalEngagements
        overallTotalImpressions
        overallUniqueRecipients
      }
    }    
`;

export const GET_CAMPAIGN_TEMPLATES = gql`
    query GetCampaignsTemplatesQuery (
      $isDraft: Int!
    ) {
        getCampaignTemplates(isDraft: $isDraft) {
          hits {
            id
            title
            description
            cadence
            notes
            isDraft
            campaignCategoryId
          }
        totalResults
      }
    }    
`;
