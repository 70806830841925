import * as Yup from 'yup';
import { REQUIRED_FIELD } from 'Constants';

const activationValidationSchema = () => Yup.object({
  name: Yup.string().trim().required(REQUIRED_FIELD),
  description: Yup.string().trim(),
  inclSegmentId: Yup.object()
    .default(null)
    .nullable()
    .when('campaignType', {
      is: 'Standard campaign', // Only required if campaignType is 'Standard'
      then: (schema) => schema.required(REQUIRED_FIELD),
      otherwise: (schema) => schema.nullable(),
    }),
  campaignCategoryId: Yup.number()
    .nullable()
    .default(null)
    .when('status', (status, schema) => (
      status !== 1 ? schema : schema.required(REQUIRED_FIELD)
    )),
  startDate: Yup.date()
    .default(null)
    .required(REQUIRED_FIELD),
  endDate: Yup.date()
    .nullable()
    .default(null)
    .when('startDate', (startDate, schema) => (
      startDate
        ? schema.min(startDate, 'Must be after start date')
        : schema
    ))
});

export default activationValidationSchema;