import { Machine, interpret } from 'xstate';
import moment from 'moment';

import {
  NUDGE_TYPES,
  INPUT_DATE_TIME_FORMAT,
  CONDITION_TYPES,
} from 'Constants';

import Analytics from 'libs/Analytics';
import { notificationService } from 'machines/notifications.machine';
import NudgeService from 'services/NudgeService';

const FILE_NAME = 'CreateNudgeMachine';

export const createOrUpdateNudgeMachine = Machine(
  {
    id: 'createNudge',
    initial: 'ready',
    states: {
      ready: {
        on: {
          CREATE: 'creating',
          UPDATE: 'updating',
        },
      },
      creating: {
        invoke: {
          src: 'createNudge',
          onDone: {
            actions: 'onDone',
            target: 'ready',
          },
          onError: {
            actions: ['onError', 'logError'],
            target: 'ready',
          },
        },
      },
      updating: {
        invoke: {
          src: 'updateNudge',
          onDone: {
            actions: 'onDone',
            target: 'ready',
          },
          onError: {
            actions: ['onError', 'logError'],
            target: 'ready',
          },
        },
      },
    },
  },
  {
    actions: {
      onDone: (ctx, evt) => {
        const { values, isUpdate } = evt.data;

        window.location.assign(
          `/nudges/${values.isDraft ? 'main?sort=1&filterStatus=1' : 'main?sort=1&filterStatus=2'}`,
        );
      },

      onError: (ctx, evt) => {
        const err = evt.data;

        notificationService.send('DISPLAY', {
          message: err.message,
          variant: 'danger',
        });

        Analytics.trackApplicationError(
          err.message,
          FILE_NAME,
          'createOrUpdateNudge',
        );
      },

      logError: (ctx, evt) => {
        const err = evt.data;

        console.error('Error occurred in createOrUpdateNudge:', err);

        if (err instanceof TypeError && err.message.includes('Cannot read properties of null')) {
          // Extract the field causing the error
          const match = /Cannot read properties of null \(reading '([^']+)'\)/.exec(err.message);
          if (match) {
            const fieldName = match[1];
            console.error(`Field '${fieldName}' is causing the error.`);
          }
        }
      },
    },
    services: {
      createNudge: async (
        ctx,
        {
          createTimeBasedNudgeMutation,
          values,
          organizationId,
        },
      ) => {
        const basePayload = {
          name: values.name,
          description: values.description || null,
          title: values.title || null,
          body: values.body || null,
          segmentId: values.segmentId && values.segmentId.value !== 0 ? values.segmentId.value : null,
          url: values.url || null,
          urlLinkType: values.urlLinkType || null,
          suppressNotification: false,
          organizationId,
          environment: values.environment?.value ?? null,
          messageCapLimitOverride: values.messageCapLimitOverride?.value ?? null,
          messageMaxFreq: values.nudgeType === NUDGE_TYPES.GEOFENCE.ID ? values.messageMaxFreq?.value ?? null : null,
        };

        // Time-based nudge
        let scheduleDateTime = null;

        if (values.scheduleNow) {
          scheduleDateTime = moment().toDate();
        } else if (values.scheduleDateTime) {
          scheduleDateTime = moment(values.scheduleDateTime, INPUT_DATE_TIME_FORMAT).toDate();
        }

        await NudgeService.createTimeBasedNudge(
          createTimeBasedNudgeMutation,
          basePayload,
          values.isDraft,
          values.scheduleNow,
          scheduleDateTime,
          values.messageCategoryId,
        );


        return { values, isUpdate: false };
      },

      updateNudge: async (
        ctx,
        {
          updateTimeBasedNudgeMutation,
          values,
        },
      ) => {
        const basePayload = {
          name: values.name,
          description: values.description || null,
          title: values.title || null,
          body: values.body || null,
          segmentId: values.segmentId && values.segmentId.value !== 0 ? values.segmentId.value : null,
          url: values.url || null,
          urlLinkType: values.urlLinkType || null,
          suppressNotification: false,
          messageCapLimitOverride: values.messageCapLimitOverride?.value ?? null,
          messageMaxFreq: values.nudgeType === NUDGE_TYPES.GEOFENCE.ID ? values.messageMaxFreq?.value ?? null : null,
        };

        // Time-based nudge
        const scheduleActionCondition = values.conditions?.find((elem: any) => Number(elem.conditionType.id) === CONDITION_TYPES.SCHEDULED_ACTION.ID);

        await NudgeService.updateTimeBasedNudge(
          updateTimeBasedNudgeMutation,
          values.actionId,
          values.messageId,
          scheduleActionCondition?.id,
          basePayload,
          values.isDraft,
          values.scheduleNow,
          values.scheduleDateTime,
          values.messageCategoryId,
        );
        return { values, isUpdate: true };
      },
    },
  },
);

export const createOrUpdateNudgeService = interpret(createOrUpdateNudgeMachine).start();