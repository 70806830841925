import { gql } from '@apollo/client';

export const GET_NUDGE = gql`
  query GetNudgeQuery($id: ID!) {
    getNudge(id: $id) {
      id
      messageId
      sent
      isDraft
      archived
      actionType {
        id
        name
        created
        updated
      } 
      conditions {
        id
        dateTimeRangeStart
        dateTimeRangeEnd
        dayOfWeek
        timeRangeStart
        timeRangeEnd
        dateTime
        radius
        speedMinimum
        speedMaximum
        delay
        conditionType {
          id
          name
          displayName
          created
          updated
        }
        location {
          id
          name
        }
        organization {
          id
          name
          website
          federationUrl
          larkyOrganizationId
          larkyEmail
          larkyPassword
          created
          updated
        }
        created
        updated
      }
      message {
        id
        messageCategoryId
        organizationId
        name
        description
        title
        body
        data
        suppressNotification
        url
        guid
        urlLinkType
        environment
        icon
        active
        created
        updated
        segmentId
        messageCapLimitOverride
        messageMaxFreq
        messageCategoryId
        tapBehavior
      }  
      created
      updated
    } 
  }
`;

export const GET_NUDGES_HEADERS = gql`
  query GetNudgesHeadersQuery($organizationId: Int!){
    getNudgesHeaders(organizationId: $organizationId){
      userOrganization
      nudgeApiVersion
      accessTokenCore
      accessTokenPush
      needToken
      authorization
    }
  }
`;

export const GET_NUDGES_RECEIVED = gql`
  query GetNudgesReceived(
    $organizationId: Int!
    $searchParam: NudgeAnalyticsParamInput
    $analyticsType: String
    $timeZone: String
  ) {
    getNudgesReceived(
      organizationId: $organizationId
      searchParam: $searchParam
      analyticsType: $analyticsType
      timeZone: $timeZone
    ) {
      timestamp
      messageName
      messageDescription
      messageTitle
      messageBody
      federationId
      }
   }
`;

export const GET_NUDGES_TAPPED = gql`
  query GetNudgesTapped(
    $organizationId: Int!
    $searchParam: NudgeAnalyticsParamInput
    $analyticsType: String
    $timeZone: String
  ) {
    getNudgesTapped(
      organizationId: $organizationId
      searchParam: $searchParam
      analyticsType: $analyticsType
      timeZone: $timeZone
    ) {
      timestamp
      messageName
      messageDescription
      messageTitle
      messageBody
      federationId
      }
   }
`;

export const GET_NUDGES_TAPPED_COUNT = gql`
  query GetNudgesTapped(
    $organizationId: Int!
    $searchParam: NudgeAnalyticsParamInput
    $analyticsType: String
    $timeZone: String
  ) {
    getNudgesTapped(
      organizationId: $organizationId
      searchParam: $searchParam
      analyticsType: $analyticsType
      timeZone: $timeZone
    ) {
      date
      count
      }
   }
`;

export const GET_NUDGES_RECEIVED_COUNT = gql`
  query GetNudgesReceived(
    $organizationId: Int!
    $searchParam: NudgeAnalyticsParamInput
    $analyticsType: String
    $timeZone: String
  ) {
    getNudgesReceived(
      organizationId: $organizationId
      searchParam: $searchParam
      analyticsType: $analyticsType
      timeZone: $timeZone
    ) {
      date
      count
      }
   }
`;

export const SEARCH_NUDGES = gql`
  query SearchNudgesQuery(
    $orderBy: String!
    $sortOrder: String!
    $rows: Int!
    $start: Int!
    $searchParam: SearchParamInput
    $dbSearch: Boolean
  ) {
    searchNudges(
      orderBy: $orderBy
      sortOrder: $sortOrder
      rows: $rows
      start: $start
      searchParam: $searchParam
      dbSearch: $dbSearch
    ) {
      hits {
        id
        messageId
        hash
        sent
        isDraft
        archived
        locations
        message
        name
        title
        type
        created
        updated
        dateTimeRangeStart
        dateTimeRangeEnd
        messageCategoryId
        messageCategoryValue
        timeBasedLaunchDate
      }
      totalResults
    } 
  }
`;

export const GET_NUDGE_SCORE = gql`
    query GetNudgeScoreQuery(
      $body: String
      $title: String
    ) {
      getNudgeScore(body: $body, title: $title) {
        nudgeScore
        nudgeScoreText
        nudgeScoreColor
        nudgeScoreHelperText
        nudgeScoreLink
      }
    }    
`;

export const GET_NUDGE_ASSIST = gql`
    query GetNudgeAssistQuery(
      $text: String
    ) {
      getNudgeAssist(text: $text) {
      rlist {
        id
        title
        description
      }
     }
    }    
`;

export const GET_NUDGE_ANALYTICS = gql`
    query GetNudgeAnalyticsQuery(
      $actionId: Int
    ) {
      getNudgeAnalytics(actionId: $actionId) {
        impressions
        engagements
        engagementRate
        isValid
        isFinalFlag
        day30AnalyticsFlag
        nudgeType
        lastWeekMembersMessaged
        lastWeekMembersMessagedPercentTrend
        lastWeekMembersMessagedTrendArrow
        lastWeekTotalImpressions
        lastWeekTotalImpressionsPercentTrend
        lastWeekTotalImpressionsTrendArrow
        lastWeekEngagements
        lastWeekEngagementsPercentTrend
        lastWeekEngagementsTrendArrow
        lastWeekEngagementsRatePercentTrend
        lastWeekEngagementsRateTrend
        lastWeekEngagementsRateTrendArrow
        lastMonthMembersMessaged
        lastMonthMembersMessagedPercentTrend
        lastMonthMembersMessagedTrendArrow
        lastMonthTotalImpressions
        lastMonthTotalImpressionsPercentTrend
        lastMonthTotalImpressionsTrendArrow
        lastMonthEngagements
        lastMonthEngagementsPercentTrend
        lastMonthEngagementsTrendArrow
        lastMonthEngagementsRatePercentTrend
        lastMonthEngagementsRateTrend
        lastMonthEngagementsRateTrendArrow
        last90dayMembersMessaged
        last90dayMembersMessagedPercentTrend
        last90dayMembersMessagedTrendArrow
        last90dayTotalImpressions
        last90dayTotalImpressionsPercentTrend
        last90dayTotalImpressionsTrendArrow
        last90dayEngagements
        last90dayEngagementsPercentTrend
        last90dayEngagementsTrendArrow
        last90dayEngagementsRatePercentTrend
        last90dayEngagementsRateTrend
        last90dayEngagementsRateTrendArrow
        locationPermissionRate
        locationPermissionRateTrend
        locationPermissionRateTrendArrow
        notificationPermissionRate
        notificationPermissionRateTrend
        notificationPermissionRateTrendArrow
      }
    }    
`;

export const GET_NUDGE_AND_CAMPAIGN_CATEGORIES = gql`
    query GetNudgeAndCampaignCategoriesQuery {
      getNudgeAndCampaignCategories {
        categories {
            categoryValue
            categoryKey
        }
      }
    }    
`;