import { Machine, interpret } from 'xstate';
import moment from 'moment';

import {
  INPUT_DATE_TIME_FORMAT,
  BEGINNING_OF_DAY_TIME,
  END_OF_DAY_TIME, NUDGE_STATUSES,
} from 'Constants';

import Analytics from 'libs/Analytics';
import { formatTimeWithDefault } from 'Utils';
import { notificationService } from 'machines/notifications.machine';
import NudgeService from 'services/NudgeService';

const FILE_NAME = 'CreateLocationNudgeMachine';

export const createOrUpdateLocationNudgeMachine = Machine(
  {
    id: 'createLocationNudge',
    initial: 'ready',
    states: {
      ready: {
        on: {
          CREATE: 'creating',
          UPDATE: 'updating',
        },
      },
      creating: {
        invoke: {
          src: 'createLocationNudge',
          onDone: {
            actions: 'onDone',
            target: 'ready',
          },
          onError: {
            actions: ['onError', 'logError'],
            target: 'ready',
          },
        },
      },
      updating: {
        invoke: {
          src: 'updateLocationNudge',
          onDone: {
            actions: 'onDone',
            target: 'ready',
          },
          onError: {
            actions: ['onError', 'logError'],
            target: 'ready',
          },
        },
      },
    },
  },
  {
    actions: {
      onDone: async (ctx, evt) => {
        const { values, isUpdate } = evt.data;
        // window.location.assign(
        //   `/locationnudges/${values.isDraft ? 'main?filterStatus=1' : 'main?filterStatus=2'}`,
        // );

         // Get today's date at the start of the day (to avoid time differences)
          const today = moment().startOf('day');

          // Parse the start date from values.dateTimeRangeStart
          const dateStart = moment(values.dateTimeRangeStart).startOf('day');

          // Determine the correct filter status based on the comparison
          const filterStatus = dateStart.isAfter(today) ? '2' : '3';

          // Construct the route, considering both the draft status and the filter status
          const route = `/locationnudges/${values.isDraft ? 'main?sort=1&filterStatus=1' : `main?sort=1&filterStatus=${filterStatus}`}`;
          // Navigate to the constructed URL
          window.location.assign(route);
        },

      onError: (ctx, evt) => {
        const err = evt.data;

        notificationService.send('DISPLAY', {
          message: err.message,
          variant: 'danger',
        });

        Analytics.trackApplicationError(
          err.message,
          FILE_NAME,
          'createOrUpdateLocationNudge',
        );
      },

      logError: (ctx, evt) => {
        const err = evt.data;

        console.error('Error occurred in createOrUpdateLocationNudge:', err);

        if (err instanceof TypeError && err.message.includes('Cannot read properties of null')) {
          // Extract the field causing the error
          const match = /Cannot read properties of null \(reading '([^']+)'\)/.exec(err.message);
          if (match) {
            const fieldName = match[1];
            console.error(`Field '${fieldName}' is causing the error.`);
          }
        }
      },
    },
    services: {
      createLocationNudge: async (
        ctx,
        {
          createGeolocationNudgeMutation,
          values,
          organizationId,
        },
      ) => {
        const basePayload = {
          name: values.name,
          description: values.description || null,
          title: values.title || null,
          body: values.body || null,
          segmentId: values.segmentId && values.segmentId.value !== 0 ? values.segmentId.value : null,
          url: values.url || null,
          urlLinkType: values.urlLinkType || null,
          suppressNotification: false,
          organizationId,
          environment: values.environment?.value ?? null,
          messageCapLimitOverride: values.messageCapLimitOverride?.value ?? null,
          messageMaxFreq: values.messageMaxFreq?.value ?? null,
        };

        // Geolocation nudge

        const radius = values.radius?.value ?? null;
        const tapBehavior = values.tapBehavior ?? null;
        const delay = values.delay?.value ?? null;

        const selectedLocationsIds = values.selectedLocations?.map(({ value }: { value: any }) => value) ?? [];
        const selectedWeekDays = values.selectedWeekDays?.map(({ value }: { value: any }) => value) ?? [];

        let startTime = formatTimeWithDefault(values.timeRangeStart, BEGINNING_OF_DAY_TIME, values.allDay);
        let endTime = formatTimeWithDefault(values.timeRangeEnd, END_OF_DAY_TIME, values.allDay);

        let dateTimeRangeStart = null;
        if (values.dateTimeRangeStartNow) {
          dateTimeRangeStart = moment().toDate();
        } else if (values.dateTimeRangeStart) {
          dateTimeRangeStart = moment(values.dateTimeRangeStart, INPUT_DATE_TIME_FORMAT).toDate();
        }

        let dateTimeRangeEnd = null;
        if (values.dateTimeRangeEnd && !values.dateTimeRangeEndNever) {
          dateTimeRangeEnd = moment(values.dateTimeRangeEnd, INPUT_DATE_TIME_FORMAT).toDate();
        }

        await NudgeService.createGeolocationNudge(
          createGeolocationNudgeMutation,
          basePayload,
          values.isDraft,
          radius,
          selectedLocationsIds,
          selectedWeekDays,
          startTime,
          endTime,
          dateTimeRangeStart,
          dateTimeRangeEnd,
          delay,
          values.messageCategoryId,
          tapBehavior,
        );
        return { values, isUpdate: false };
      },

      updateLocationNudge: async (
        ctx,
        {
          updateGeolocationNudgeMutation,
          values,
        },
      ) => {
        const basePayload = {
          name: values.name,
          description: values.description || null,
          title: values.title || null,
          body: values.body || null,
          segmentId: values.segmentId && values.segmentId.value !== 0 ? values.segmentId.value : null,
          url: values.url || null,
          urlLinkType: values.urlLinkType || null,
          suppressNotification: false,
          messageCapLimitOverride: values.messageCapLimitOverride?.value ?? null,
          messageMaxFreq: values.messageMaxFreq?.value ?? null,
        };

        // Geolocation nudge

        const radius = values.radius?.value ?? null;
        const delay = values.delay?.value ?? null;
        const tapBehavior = values.tapBehavior ?? null;

        const selectedWeekDays = values.selectedWeekDays?.map(({ value }: { value: any }) => value) ?? [];
        const selectedLocationsIds = values.selectedLocations?.map(({ value }: { value: any }) => value) ?? [];

        let startTime = formatTimeWithDefault(values.timeRangeStart, BEGINNING_OF_DAY_TIME, values.allDay);
        let endTime = formatTimeWithDefault(values.timeRangeEnd, END_OF_DAY_TIME, values.allDay);

        let dateTimeRangeStart = null;
        if (values.dateTimeRangeStartNow) {
          dateTimeRangeStart = moment().toDate();
        } else if (values.dateTimeRangeStart) {
          dateTimeRangeStart = moment(values.dateTimeRangeStart, INPUT_DATE_TIME_FORMAT).toDate();
        }

        let dateTimeRangeEnd = null;
        if (values.dateTimeRangeEnd && !values.dateTimeRangeEndNever) {
          dateTimeRangeEnd = moment(values.dateTimeRangeEnd, INPUT_DATE_TIME_FORMAT).toDate();
        }

        await NudgeService.updateGeolocationNudge(
          updateGeolocationNudgeMutation,
          values.actionId,
          values.messageId,
          basePayload,
          values.isDraft,
          radius,
          selectedWeekDays,
          selectedLocationsIds,
          startTime,
          endTime,
          dateTimeRangeStart,
          dateTimeRangeEnd,
          delay,
          values.messageCategoryId,
          tapBehavior,
        );

        return { values, isUpdate: true };
      },
    },
  },
);

export const createOrUpdateLocationNudgeService = interpret(createOrUpdateLocationNudgeMachine).start();
