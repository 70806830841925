import * as Yup from 'yup';

import {
  REQUIRED_FIELD,
  rfc1738UrlRegex,
} from 'Constants';

const validationSchema = (campaignType) => Yup.object({
  environment: Yup.object().required(REQUIRED_FIELD),
  name: Yup.string().trim().required(REQUIRED_FIELD),
  description: Yup.string().nullable(),
  title: Yup.string()
    .trim()
    .required(REQUIRED_FIELD),
  body: Yup.string()
    .trim()
    .required(REQUIRED_FIELD),
  url: Yup.string().trim().nullable()
    .when('urlLinkType.value', (urlLinkTypeValue, schema) => {
      const processedValue = Array.isArray(urlLinkTypeValue) ? urlLinkTypeValue[0] : urlLinkTypeValue;
      if (processedValue === 'Weblink') {
        return schema.test('urlTestType', 'Please enter a valid URL', function (value) {
          const { url } = this.parent || {};
          const regex = new RegExp(rfc1738UrlRegex);
          return url && url.slice(0, 4) !== 'tel:' ? regex.test(url) : true;
        });
      } else {
        return schema.nullable();
      }
    }),

  // Require `daysDelay` only for Standard Campaign
  daysDelay: Yup.object().nullable()
    .when('campaignType', {
      is: 'Standard campaign',
      then: () => Yup.object().required(REQUIRED_FIELD),
      otherwise: () => Yup.mixed().nullable(),
    }),

  scheduleTimeHour: Yup.object().nullable().default(null),
  scheduleTimeMinute: Yup.object().nullable().default(null),
  scheduleTimeType: Yup.object().nullable().default(null),

  // Require `selectedWeekDays` only for Standard campaign
  selectedWeekDays: Yup.array()
    .of(Yup.object().required(REQUIRED_FIELD))
    .when('campaignType', {
      is: 'Standard campaign',
      then: () => Yup.array().min(1, REQUIRED_FIELD), // Must have at least one day selected
      otherwise: () => Yup.array().nullable(),
    }),

  // GUID is required only for API-triggered campaigns
  guid: Yup.string()
  .when('campaignType', (campaignType, schema) => {
    return campaignType === 'API-triggered campaign'
      ? schema.required(REQUIRED_FIELD)
      : schema.nullable();
  }),
});

export default validationSchema;
