import React from 'react';
import PropTypes from 'prop-types';
import { CADENCE_OPTIONS, NON_PRODUCTION_CADENCE_OPTIONS } from 'Constants';
import RowActionsDropdown from '../../../../components/CustomTable/RowActionsDropdown/RowActionsDropdown';

// styles
import styles from '../../../Nudges/components/NudgeRow/NudgeRow.module.scss';

const CampaignMessagesRow = ({
  data,
  status,
  handleDeleteRow,
  handleEditRow,
  readOnly,
  campaignType,
}) => {
  const getDeliveryLabel = () => {
    const options = NON_PRODUCTION_CADENCE_OPTIONS.concat(CADENCE_OPTIONS)
      .sort(
        (a, b) => ((a.value > b.value) ? 1 : -1),
      );
    const found = options.find(({ value }) => value.toString() === data.daysDelay);
    return found ? found.label : '';
  };
  const getHandleDeleteRow = () => {
    if (readOnly) return null;
    return handleDeleteRow();
  };

  const createCampaignMessagesOptionList = () => {
    const listOfOptions = [];
    listOfOptions.push({ label: !readOnly ? 'View/Edit nudge' : 'View nudge', value: 'edit' });
    if (handleDeleteRow && !readOnly && status !== 1) {
      listOfOptions.push({ label: null, value: null, isLineBreak: true });
      listOfOptions.push({ label: 'Delete nudge', value: 'delete' });
    }
    return listOfOptions;
  };

  return (
    <tr className={styles.customRow}>
      <td>{data.name}</td>
      <td>{data.title}</td>
      <td>{data.message}</td>
      {campaignType === 'Standard campaign' && <td>{getDeliveryLabel()}</td>}
      <td>
        <RowActionsDropdown
          archived={readOnly}
          onDeleteRow={!readOnly && status !== 1 ? () => getHandleDeleteRow() : null}
          onEditRow={handleEditRow}
          hasEditPermission={!readOnly}
          options={createCampaignMessagesOptionList()}
          placeholder="Actions..."
        />
      </td>
    </tr>
  );
};

CampaignMessagesRow.propTypes = {
  data: PropTypes.shape({
    messageId: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    daysDelay: PropTypes.string,
    selected: PropTypes.bool.isRequired,
  }).isRequired,
  status: PropTypes.number,
  handleEditRow: PropTypes.func,
  handleDeleteRow: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

CampaignMessagesRow.defaultProps = {
  status: 0,
  handleEditRow: () => null,
  readOnly: false,
};

export default CampaignMessagesRow;
